import { render, staticRenderFns } from "./ContestSignup.vue?vue&type=template&id=6fdcddc8&scoped=true&"
import script from "./ContestSignup.vue?vue&type=script&lang=ts&"
export * from "./ContestSignup.vue?vue&type=script&lang=ts&"
import style0 from "./ContestSignup.vue?vue&type=style&index=0&id=6fdcddc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdcddc8",
  null
  
)

export default component.exports