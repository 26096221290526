





































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Glitch from '../components/effects/Glitch.vue';

@Component({
  components: {
    Glitch
  },
  async beforeRouteEnter(to, from, next) {
    const [data, discordUsers] = (await Promise.all([
      axios.get('/api/signup/data'),
      axios.get('/api/signup/users')
    ])).map(it => it.data)

    if (!data)
      alert('An error occurred')

    if(data.participant)
      return next({name: 'Already Registered'})

    next((vm: any) => {
      vm.user = data.user
      vm.discordUsers = discordUsers
    })
  }
})
export default class ContestSignup extends Vue {

  user: any = null;
  discordUsers = [] as any[]
  discordUser: any = null
  searchTerm = ''

  get filteredUserList() {
    if (this.searchTerm.trim().length === 0)
      return []

    return this.discordUsers.filter(user => `${user.displayName.toLowerCase()}.${user.discriminator}`.includes(this.searchTerm.toLowerCase()))
      .slice(0, 5)
  }


  async signup() {
    try {
      const response = (await axios.post('/api/signup', {
      discordUser: this.discordUser!.id
    })).data as any
    
    if(response.id)
      this.$router.push('/vbr3/signup/success')
    } catch(e) {
      alert("An error occured. Ping marvin about it and he'll fix it once he gets out of bed.")
    }
  }

}

